import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import { Link } from "gatsby"


const Card = ({
                  name,
                  tag = null,
                  alternativeText,
                  image,
                  description = null,
                  extraClassName = null,
                  onClick = null,
                  lang = "en",
                  showMore = false,
                  url = null,
                  download = false,
                  animation = {}
              }) => {
    let className = "card"
    let props = {}

    if (extraClassName) {
        className += ` ${extraClassName}`
    }

    if (onClick) {
        props["onClick"] = onClick
    }

    const showMoreButton = {
        "en": "Read more",
        "es": "Leer más"
    }

    let title = <h5>
        {name}
        {tag && <span className="tag tag--status">{tag}</span>}
    </h5>

    if (url != null) {
        if (download) {
            title = <h5>
                <a href={url} download={true}>{name}</a>
                {tag && <span className="tag tag--status">{tag}</span>}
            </h5>
        }

        title = <h5>
            <Link to={url}>{name}</Link>
            {tag && <span className="tag tag--status">{tag}</span>}
        </h5>
    }

    return <div className={className} {...props} {...animation}>
        <div className="card__body">
            {image !== null && typeof image !== "undefined" && <img src={image.localFile.publicURL} alt={alternativeText} />}
            <div className="card__body__description">
                {title}
                <ReactMarkdown
                    children={description}
                    rehypePlugins={[rehypeRaw]}
                />
            </div>
            {showMore && <span className="see-more">{showMoreButton[lang]} <i className="icon-arrow-right" /></span>}
        </div>
    </div>
}

export default Card
