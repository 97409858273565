import { graphql } from "gatsby"
import React from "react"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw"
import NewLayout from "../components/new_layout"
import Seo from "../components/seo"
import Card from "../components/v2023/UI/Card/card"

const SolutionCategory = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const category = data.category //categoría actual
    const sectors = data.sectors.edges
    const solutions = data.solutions.edges //soluciones de la categoría
    const baseUrl = lang === "en" ? "" : `/${lang}`

    return <NewLayout pageContext={pageContext}>
        <Seo
            lang={pageContext.langKey}
            title={category.seo.title}
            description={category.seo.meta_description}
            image={category.seo?.image_2?.localFile?.publicURL}
            translates={pageContext.translates}
        />

        <main className="main">
            <div className="main__section main__section--100 white-section brown" >
                <div className="container" data-aos="fade-up">
                    <h1 lang={lang} className="merriweather">{category.title}</h1>
                    <div className="container__text">
                        <p>
                            <ReactMarkdown children={category.description} rehypePlugins={[rehypeRaw]} />
                        </p>
                    </div>
                    <div className="grid-lg-3">
                        {sectors.map((sector, index) => (
                            <Card
                                key={index}
                                name={sector.node.tag}
                                image={sector.node.icon}
                                alternativeText={sector.node.icon.alternativeText}
                                description={sector.node.description}
                                showMore={true}
                                url={sector.node.url}
                                lang={lang}
                            />
                        ))}
                        {solutions.map((solution, index) => (
                            <Card
                                key={index}
                                name={solution.node.name}
                                image={solution.node.icon_new}
                                alternativeText={solution.node.icon_new.alternativeText}
                                description={solution.node.short_description}
                                showMore={true}
                                url={`${baseUrl}/${category.url}/${solution.node.url}`}
                                lang={lang}
                            />
                        ))}
                    </div>
                </div>
            </div>
            {/* <div className="main__section main__section--100">
                <div className="container">
                    <div className="tile tile--image ">
                        <div className="tile__body">
                            <h3>{other.title}</h3>
                            <ReactMarkdown
                                children={other.description}
                                rehypePlugins={[rehypeRaw]}
                            />
                            <Link to={`${baseUrl}/${other.url}`} className="button button--small">{other.button_text} <i className="icon-arrow-right"></i></Link>
                        </div>
                        <div className="tile__image">
                            <GatsbyImage alt={other.image.alternativeText} image={getImage(other.image.localFile)} />
                        </div>
                    </div>
                </div>
            </div> */}
        </main>
    </NewLayout>
}

export default SolutionCategory

export const solutionCategoryQuery = graphql`
    query ($id: String!, $idSearch: Int!, $langKey: String!) {
        category: strapiIndustriesCategory (locale: {eq: $langKey}, id: {eq: $id}) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL
                    }
                }
            }
            title
            description
            url
            button_text
            image {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            height: 226
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
        }
        solutions: allStrapiIndustry ( filter: { locale: { eq: $langKey }, industries_category: {id: {eq: $idSearch}} } ) {
            edges {
                node {
                    seo {
                        meta_description
                    }
                    name
                    title
                    short_description
                    url
                    orderId
                    icon_new {
                        name
                        alternativeText
                        localFile{
                            publicURL
                        }
                    }
                }
            }
        }
        sectors: allStrapiSector ( filter: { locale: { eq: $langKey } } ) {
            edges {
                node {
                    seo {
                        meta_description
                    }
                    tag
                    description
                    icon {
                        name
                        alternativeText
                        localFile{
                            publicURL
                        }
                    }
                    url
                }
            }
        }
        other: strapiIndustriesCategory (locale: {eq: $langKey}, id: {ne: $id}) {
            seo {
                title
                meta_description
                image_2 {
                    alternativeText
                    localFile {
                        publicURL

                    }
                }
            }
            title
            description
            url
            button_text
            image {
                name
                alternativeText
                localFile {
                    childImageSharp {
                        gatsbyImageData(
                            height: 226
                            placeholder: BLURRED
                            formats: [WEBP]
                        )
                    }
                }
            }
        }
    }
`
